export default {
    blurple: "#7289DA",
    darkerBlurple: "#4E609C",
    darkButNotBlack: "#2C2F33",
    notQuiteBlack: "#23272A",
    greyple: "#99AAB5",
    darkerGreyple: "#6E7D88",
    error: "#f04747",
    success: "#43b581"
};
